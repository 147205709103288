import React from "react"
import parse from "html-react-parser"

import Section from "./Section"
import "aos/dist/aos.css"

const PageContent = ({ copy }) => {
  return (
    <>
      <Section additionalClasses="section has-background-bis content-simple-section">
        <div className="page-content">
          <div data-aos="fade-up" data-aos-offset="10" className="columns">
            <div className="is-size-para is-size-para-mobile has-text-white content-block column is-9">
              {parse(copy)}
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

export default PageContent
