import React, { useState } from "react"
import parse from "html-react-parser"
import { Swiper, SwiperSlide } from "swiper/react"
import { getImage } from "gatsby-plugin-image"
import { FreeMode, Navigation, Pagination, Thumbs } from "swiper/modules"

import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "aos/dist/aos.css"

import Section from "./Section"

const TabsHowWeDo = ({ title, items }) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)
  const [activeIndex, setActiveIndex] = useState(0)

  const handleSlideChange = swiper => {
    setActiveIndex(swiper.activeIndex)
  }

  return (
    <>
      <Section
        additionalClasses="section has-text-white bg-black tabs-section"
        key={0}
      >
        <div
          className="aos-item"
          data-aos="fade-up"
          data-aos-offset="10"
          data-aos-delay="10"
        >
          <div className="section-title">
            <h2 className="is-size-3 is-size-3-mobile has-text-weight-bold">
              {title}
            </h2>
          </div>
          <div className="page-content">
            <div className="slide-component">
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={"auto"}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Thumbs]}
                className="nav-tabs"
              >
                {items.map((item, index) => (
                  <SwiperSlide
                    key={index}
                    className={`nav-tab-item ${
                      activeIndex === index ? "active" : ""
                    }`}
                  >
                    <p>{item.tabTitle}</p>
                  </SwiperSlide>
                ))}
              </Swiper>
              <Swiper
                style={{
                  "--swiper-navigation-color": "#fff",
                  "--swiper-pagination-color": "#FFF335",
                  "--swiper-pagination-bullet-inactive-color": "#5F5B11",
                  "--swiper-pagination-bullet-inactive-opacity": "1",
                  "--swiper-navigation-size": "25px",
                  "--swiper-pagination-bullet-width": "7px",
                  "--swiper-pagination-bullet-height": "7px",
                  paddingBottom: "50px",
                }}
                modules={[FreeMode, Navigation, Pagination, Thumbs]}
                spaceBetween={10}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper }}
                pagination={{ clickable: true }}
                onSlideChange={swiper => handleSlideChange(swiper)}
                className="items-tabs"
                breakpoints={{
                  0: {
                    slidesPerView: "auto",
                    spaceBetween: 0,
                    style: {
                      "--swiper-navigation-size": "10px !important",
                      "--swiper-navigation-size": "15px !important",
                    },
                  },
                  769: {},
                }}
              >
                {items.map((item, index) => {
                  return (
                    <SwiperSlide
                      key={index}
                      className={`tab-item-content 
                      ${
                        activeIndex === index &&
                        index === 0 &&
                        "concepting-block"
                      } 
                      ${
                        activeIndex === index && index === 1 && "strategy-block"
                      } 
                      ${activeIndex === index && index === 2 && "build-block"} 
                      ${activeIndex === index && index === 3 && "launch-block"} 
                      ${
                        activeIndex !== index &&
                        "concepting-block-hide strategy-block-hide build-block-hide launch-block-hide "
                      }`}
                    >
                      <div className="contenet-coll">
                        <div className="content-block col-md is-size-para is-size-para-mobile">
                          <h3 className="is-size-4 is-size-4-mobile has-text-weight-semibold">
                            {item.title}
                          </h3>
                          <p>{parse(item.copy)}</p>
                        </div>

                        {item.itemsType === "items" && (
                          <div className="info-list">
                            {item.items.map((itemChild, index) => (
                              <div
                                className="info-list-item"
                                data-aos="zoom-in"
                                data-aos-offset="10"
                                data-aos-delay="100"
                                data-aos-once="true"
                                key={index}
                              >
                                <div className="display-flex align-center">
                                  <div className="icon">
                                    <img
                                      src={
                                        getImage(itemChild.icon.localFile)
                                          .images.fallback.src
                                      }
                                    />
                                  </div>
                                  <div className="content ">
                                    <h4 className="is-size-4-mobile">
                                      {itemChild.title}
                                    </h4>
                                    <a
                                      href={itemChild.cta.url}
                                      className="cta visible-device"
                                    >
                                      {itemChild.title}
                                    </a>
                                  </div>
                                </div>
                                <div className="content-block hidden-device ">
                                  <p>{parse(itemChild.copy)}</p>
                                  <a href={itemChild.cta.url} className="cta">
                                    {itemChild.title}
                                  </a>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        {item.itemsType === "images" && (
                          <div className="image-list">
                            <div
                              className="icon-dark-gray"
                              data-aos="fade-left"
                              data-aos-offset="10"
                              data-aos-duration="300"
                              data-aos-delay="100"
                              data-aos-once="false"
                            >
                              <img
                                src={
                                  getImage(item.images[0].image.localFile)
                                    .images.fallback.src
                                }
                                alt=""
                              />
                            </div>
                            <div
                              className="icon-light-gray"
                              data-aos="fade-left"
                              data-aos-offset="10"
                              data-aos-duration="300"
                              data-aos-delay="400"
                              data-aos-once="false"
                            >
                              <img
                                src={
                                  getImage(item.images[1].image.localFile)
                                    .images.fallback.src
                                }
                                alt=""
                              />
                            </div>
                            <div
                              className="icon-yellow aos-animate"
                              data-aos="fade-left"
                              data-aos-offset="10"
                              data-aos-duration="300"
                              data-aos-delay="600"
                              data-aos-once="false"
                            >
                              <img
                                src={
                                  getImage(item.images[2].image.localFile)
                                    .images.fallback.src
                                }
                                alt=""
                              />
                            </div>
                          </div>
                        )}

                        {item.itemsType === "image" && (
                          <div className="image-block">
                            <div
                              className="aos-item"
                              data-aos="flip-left"
                              data-aos-anchor-placement="center-bottom"
                              data-aos-mirror="true"
                              data-aos-offset="10"
                              data-aos-duration="100"
                            >
                              <img
                                src={
                                  getImage(item.image.localFile).images.fallback
                                    .src
                                }
                                alt=""
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

export default TabsHowWeDo
