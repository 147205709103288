import React, { useEffect } from "react"
import { graphql } from "gatsby"

/* NPM */
import "aos/dist/aos.css"

/* Indie Components */
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import MountainHeader from "../components/MountainHeader"
import PageWrapper from "../components/PageWrapper"
import PageTitleSection from "../components/PageTitleSection"
import TabsHowWeDo from "../components/TabsHowWeDo"
import PageContent from "../components/PageContent"

const ServicesBranding = ({ data: { page } }) => {
  useEffect(() => {
    const offset = window.innerWidth > 500 ? 360 : 180
    const AOS = require("aos")
    AOS.init({
      duration: 700,
      offset: offset,
      disable: function () {
        return /bot|googlebot|crawler|spider|robot|crawling/i.test(
          navigator.userAgent
        )
      },
    })

    if (AOS) {
      AOS.refresh()
    }
  }, [])

  return (
    <Layout
      showFooter={page.pageSettings.showFooter}
      footerTitle={`Looking for a standout <br/> brand that attracts clients?`}
    >
      <Seo seo={page.seo} />
      <MountainHeader headerImages={page.headerSettings.headerImages} />
      <PageWrapper additionalClassName="services-page">
        <PageTitleSection
          title={page.headerSettings.title}
          subtitle={page.headerSettings.subTitle}
          description={
            page.ServiceBrandingFields.brandingDescription.description
          }
        />
        <TabsHowWeDo {...page.ServiceBrandingFields.howWeDoIt} />
        <PageContent {...page.ServiceBrandingFields.bottomContent} />
      </PageWrapper>
    </Layout>
  )
}

export default ServicesBranding

export const ServicesBrandingQuery = graphql`
  query ServicesBrandingId(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    page: wpPage(id: { eq: $id }) {
      ...pageFields
      ServiceBrandingFields {
        howWeDoIt {
          title
          items {
            title
            tabTitle
            itemsType
            copy
            items {
              title
              cta {
                url
              }
              icon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: NONE
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              copy
            }
            images {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: FULL_WIDTH
                      placeholder: NONE
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    layout: FULL_WIDTH
                    placeholder: NONE
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }

        bottomContent {
          copy
        }
        brandingDescription {
          description
        }
      }
    }
  }
`
